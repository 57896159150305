import axios from 'axios';
// const BASE_URL = 'http://localhost:5000'; //for local, this must match port in server.js
const BASE_URL = 'https://pixelheads-server.herokuapp.com'; // for Prod, this must point to your hosted server address (not client), then in allowedOrigins, point to client
// const BASE_URL = 'http://localhost:3500'; // testing blog

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
