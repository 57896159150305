import React from 'react';
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";


const SignOutPage = () => {
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/linkpage');
    }

    return (
        <a className="sign-out flex-group flex-align-center flex-nowrap" onClick={signOut}>
            <span className="material-icons padding-right-tiny dark-grey">person</span>
            Sign Out
        </a>
    )
}

export default SignOutPage
