import React from 'react';
import { Link } from 'react-router-dom';

function ProjectsMenu() {
  return (
    <nav className="projects-menu">
      <h4>Projects</h4>
      <ul>
        <li><Link to="/aa"><i></i>Anglo American</Link></li>
        <li><Link to="/umg"><i></i>Universal Music</Link></li>
        <li><Link to="/turner"><i></i>Turner</Link></li>
        <li><Link to="/bbc"><i></i>BBC Worldwide</Link></li>
        <li><Link to="/monum"><i></i>Monum</Link></li>
        <li><Link to="/setanta"><i></i>Setanta</Link></li>
        <li><Link to="/guinness"><i></i>Guinness</Link></li>
        <li><Link to="/private"><i></i>Private Jobs</Link></li>
      </ul>
    </nav>
  );
}

export default ProjectsMenu;
