import { Link } from "react-router-dom"
import SignOut from '../../../header/SignOut';

const Header = () => {

    return (
        <header className="main-header blog-header flex-group space-between flex-align-center">
            <div className="flex-group flex-start flex-align-center">
                <Link to="/" className="flex-group flex-start flex-align-center margin-right-large">
                    <span className="material-icons padding-right-small">home</span>
                    <span>Back to portfolio</span>
                </Link>
            </div>
            <div className="flex-group flex-start flex-align-center">
                <SignOut />
            </div>
        </header>
    )
}

export default Header