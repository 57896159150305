import React from 'react';

export default props => {
// reference here https://video-react.js.org/
  return (
    <video
        src="../aa/core-landing-page.mp4"
        controls
        width={"100%"}
        muted
        autoPlay={"autoplay"}
        preload="auto"
        loop 
        > 
    </video>
  );
};