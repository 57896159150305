const Footer = () => {
    return (
        <footer className="footer flex-group space-between">
            <div className="flex-group flex-start flex-nowrap">
                <div className="house">
                    <div>
                        <h3>Info</h3>
                        <p>
                            This is my PixelHeads Blog. I set this up mainly to demonstrate
                            my CRUD React/Redux skills.
                        </p>
                        <p>
                            If you would like to work with me on a project in the future. 
                            Please <br/><a href="mailto:info@pixelheads.co.uk">email me today</a> 
                        </p>
                    </div>
                </div>
                <div className="tree"></div>
                <div className="bench"></div>
            </div>
            <div className="flex-group flex-start flex-nowrap">
               <div className="grass"></div>  
               <div className="tunnel"></div>  
            </div>
           
        </footer>
    )
}

export default Footer