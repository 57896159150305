import ReactBody from 'react-body';
import { goToTop } from 'react-scrollable-anchor';

// images
import BannerImage from '../../images/clients/pages/aa/banner.png';
import DesignLanguage from '../../images/clients/pages/aa/dl.png';

// videos
import CORE from '../../videos/clients/aa/core';

// includes
import ProjectsNav from '../menus/ProjectsNav';
import ProjectsMenu from '../menus/ProjectsMenu';

function AA() {
    goToTop()
    return (
        <div>
            <ReactBody className="aa projects" />
            <ProjectsNav />
            <div className="banner aa-active">
                <ProjectsMenu />
                <nav className="downloads-menu">
                    <h3 className="white align-right border-none">Related</h3>
                    <ul>
                        <li>
                            <a href="https://designlang.herokuapp.com/designlanguage/" title="Will open in a New Window" rel="noopener noreferrer" target="_blank">Design Language</a>
                        </li>
                    </ul>
                </nav>
                <header className="projects-header">
                    <h1>Anglo American</h1>
                    <ul className="umg-overview">
                        <li>
                            <h3>Client</h3>
                            <p>Anglo American</p>
                        </li>
                        <li>
                            <h3>Platform</h3>
                            <p>Desktop & Mobile</p>
                        </li>
                        <li>
                            <h3>Services</h3>
                            <p>Contract UX,<br />Front-End Development</p>
                        </li>
                        <li>
                            <h3>When</h3>
                            <p>Feb 2020 - Present</p>
                        </li>
                    </ul>
                </header>
                <img src={BannerImage} alt="" data-aos="fade-in" data-aos-duration="1000" />
            </div>
            <div className="container-fluid col-xs-12 col-sm-12 col-md-12 col-lg-12 umg-mobile">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="flex-group space-between flex-align-center">
                            <h2>Design Language</h2>
                            <a
                                className="margin-top-med red flex-align-center"
                                href="https://designlanguage.netlify.app/"
                                title="Will open in a New Window"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <span className="material-icons">insert_link</span>Visit Design Language
                            </a>
                        </div>
                        <img className="w-100" src={DesignLanguage} alt="" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h2>Core Landing</h2>
                        <CORE />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AA;
