import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SignOut from './SignOut';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isActiveIndex: "0",
      show: false,
      showMenu: false
    }
    this.toggleActiveClass.bind(this);
    this.changeActiveMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleActiveClass(index) {
    this.setState({ isActiveIndex: index });
    this.toggleMenu();
  }

  changeActiveMenu(index) {
    if (index >= 0 && index <= 5) {
      this.setState({ isActiveIndex: index });
    }
  }

  toggleMenu() {
    this.setState((prev, props) => {
      return {
        ...prev,
        show: !prev.show
      }
    })
  }

  render() {
    //console.log(this.state.isActiveIndex);
    return (
      <header className="main-header">
        
        <section className="flex-group">
          <div className="flex-group flex-align-center flex-start flex-nowrap">
            <div className="device desktop material-icons" title="Desktop">desktop_windows</div>
            <div className="device tablet material-icons" title="Tablet">tablet_mac</div>
            <div className="device mobile material-icons" title="Mobile">phone_iphone</div>
            <nav className="main-nav">
              <a className={this.state.isActiveIndex === "0" ? 'active home' : ''} href='#section-home'>Home</a>
              <a className={this.state.isActiveIndex === "1" ? 'active about' : ''} href='#section-about'>About</a>
              <a className={this.state.isActiveIndex === "2" ? 'active about' : ''} href='#section-approach'>Approach</a>
              <a className={this.state.isActiveIndex === "3" ? 'active projects' : ''} href='#section-projects'>Projects</a>
              <a className={this.state.isActiveIndex === "4" ? 'active contact' : ''} href='#section-contact'>Contact</a>
            </nav>
            <div className={"main-nav-small " + (this.state.show ? 'show-menu' : '')}>
              <i onClick={this.toggleMenu} className="menu-icon" alt=""><div></div><div></div><div></div></i>
              <div onClick={this.toggleMenu} className="tint"></div>
              <nav>
                <a className={this.state.isActiveIndex === "0" ? 'active home' : ''} href='#section-home'>Home</a>
                <a className={this.state.isActiveIndex === "1" ? 'active about' : ''} href='#section-about'>About</a>
                <a className={this.state.isActiveIndex === "2" ? 'active about' : ''} href='#section-approach'>Approach</a>
                <a className={this.state.isActiveIndex === "3" ? 'active projects' : ''} href='#section-projects'>Projects</a>
                <a className={this.state.isActiveIndex === "4" ? 'active contact' : ''} href='#section-contact'>Contact</a>
              </nav>
            </div>
          </div>

          <div className="right-section flex-group flex-end flex-nowrap flex-align-center">
            {
              /*
              <Link className="flex-group flex-align-center" to="/blog-home">
                <span className="material-icons padding-right-tiny dark-grey">edit_note</span>
                Blog
              </Link>

            <SignOut />
              */
            }
          </div>
        </section>

      </header>
    );
  }
}

export default Header;
