import ReactBody from 'react-body';
import { goToTop } from 'react-scrollable-anchor';

// images
import BannerImage from '../../images/clients/pages/private-jobs/banner.png';
import OdiseaCats from '../../images/clients/pages/private-jobs/odisea.png';

// includes
import ProjectsNav from '../menus/ProjectsNav';
import ProjectsMenu from '../menus/ProjectsMenu';

function PrivateJobs() {
  goToTop()
  return (
    <div>
      <ReactBody className="private projects" />
      <ProjectsNav />
      <div className="banner private-active">
        <ProjectsMenu />
        <header className="projects-header">
          <h1>Private Jobs</h1>
          <ul>
            <li>
              <h3>Client</h3>
              <p>Guinness</p>
            </li>
            <li>
              <h3>Platform</h3>
              <p>Desktop</p>
            </li>
            <li>
              <h3>Services</h3>
              <p>UX UI Design<br />
                Conceptualisation
              </p>
            </li>
            <li>
              <h3>When</h3>
              <p>Pre 2013</p>
            </li>
          </ul>
        </header>
        <img src={BannerImage} alt="" data-aos="fade-in" data-aos-duration="1000" />
      </div>
      <div className="container-fluid col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="row">
          <div className="flex-group space-between flex-align-center">
            <div>
              <h2>Odisea Catamarans</h2>
              <p>Private job to design a brand identity and website using ReactJS with NodeJS, ServerJS and MongoDB on the back-end</p>
            </div>

            <a
              className="margin-top-med red flex-align-center"
              href="https://odisea-catamarans.herokuapp.com/"
              title="Will open in a New Window"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="material-icons">insert_link</span>Visit Odisea Catamarans
            </a>
          </div>
        </div>
        <div className="row margin-bottom-med">
          <figure>
            <img src={OdiseaCats} className="w-100" alt="" />
          </figure>
        </div>
      </div>
    </div>
  );
}

export default PrivateJobs;
