import { Outlet } from 'react-router-dom';
import { Link } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';
import ReactBody from 'react-body';

const Layout = () => {
    return (
        <>
            <ReactBody className="blog" />
            <Header />
            <main>
                <section className="top-section flex-group flex-column">
                    <h1>PixelHeads Blog</h1>
                    <nav className="flex-group flex-start">
                        <Link to="/blog-home">Home</Link>
                        <Link to="post">Post</Link>
                        <Link to="user">Users</Link>
                    </nav>
                </section>
                <Outlet />
                <Footer />
            </main>
        </>
    )
}

export default Layout