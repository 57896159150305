import PostAuthor from "./PostAuthor";
import TimeAgo from "./TimeAgo";
import ReactionButtons from "./ReactionButtons";
import { Link } from 'react-router-dom';
import { useGetPostsQuery } from './postsSlice';
import ReactBody from 'react-body';

const PostsExcerpt = ({ postId }) => {

    const { post } = useGetPostsQuery('getPosts', {
        selectFromResult: ({ data }) => ({
            post: data?.entities[postId]
        }),
    })

    return (
        <article>
            <ReactBody className="home" />
            <h2>{post.title}</h2>
            <pre className="excerpt">{post.body.substring(0, 500)}...</pre>
            <p className="post-credit flex-group flex-start">
                <Link to={`post/${post.id}`}>View Post</Link>
                <PostAuthor userId={post.userId} />
                <TimeAgo timestamp={post.date} />
            </p>
            <ReactionButtons post={post} />
        </article>
    )
}

export default PostsExcerpt