import { Link } from 'react-router-dom';

import LinkedIn from '../../images/social/linkedin.svg';
import FB from '../../images/social/fb.svg';
import GitHub from '../../images/social/github.svg';

function Landing() {
  const handleQuoteOfTheDay = () => {
    const quotes = [
      'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful',
      'The price of success is hard work, dedication to the job at hand, and the determination that whether we win or lose, we have applied the best of ourselves to the task at hand',
      'Coming together is a beginning; keeping together is progress; working together is success',
      'Always be yourself, express yourself, have faith in yourself, do not go out and look for a successful personality and duplicate it.'
    ]
    const int = Math.floor(Math.random() * 4);
    return quotes[int];
  }

  return (
    <div>
      <div className="quote-of-the-day">
        <span className="open">"</span>
        {handleQuoteOfTheDay()}
        <span className="closed">"</span>
      </div>
      <section className="street">
        <div className="balloon">
          <div></div>
        </div>
        <div className="tumbleweed">
          <div></div>
        </div>
        <div className="plane">
          <div>
            <div></div>
          </div>
        </div>
        <div className="line"></div>
        <div className="house">
          <div className="arch"></div>
        </div>
        <div className="train"></div>
        <div className="sign"></div>
        <div className="uni">
          <section className="hover"></section>
          <strong>Ravensbourne College of <br />Design & Communication</strong>
          <article>
            <h4>University</h4>
            Mike graduates with an honours degree
            in Multi-Media & Visual Communication at 
            Ravenbourne College of Design & Communication
          </article>
        </div>
        <Link className="brann" to="/monum">
          <section className="hover"></section>
          <strong>Brann/Fullsix</strong>
          <article>
            <h4>BRANN DIGITAL</h4>
            Mike starts his career working client-side
            designing websites for global brands such as
            Guinness, Coca Cola, Barclays, Sainsburys and many others.
            This is where he also starts learning HTML, JavaScript and CSS
          </article>
        </Link>
        <div className="pfizer">
          <strong>Pfizer</strong>
          <article>
            <h4>PFIZER</h4>
            Mike manages the Digital Media department
            within creative services, a small team of .Net devs & Designers.
            This was a hands on role and he got involved in the design and
            front-end development on the Pfizer Intranet
          </article>
        </div>
        <div className="discovery">
          <strong>Discovery Channel</strong>
          <article>
            <h4>DISCOVERY CHANNEL</h4>
            Mike only stayed at Discovery for a short time on this contract.
            Although it was good experience working with the sales teams, there
            wasn't enough design or developement work to keep him around
          </article>
        </div>
        <Link className="bbc" to="/bbc">
          <strong>BBC Worldwide</strong>
          <article className="right">
            <h4>BBC WORLDWIDE</h4>
            Mike starts a contract at BBC Worldwide designing and developing the
            BBC Motion Gallery website, the new TopGear website and interactive
            sales presentation for Topgear TV merchandise
          </article>
        </Link>
        <Link className="turner" to="/turner">
          <strong>Turner Inc</strong>
          <article className="right">
            <h4>TURNER BROADCASTING</h4>
            Mike starts a contract with Turner Broadcasting Inc, designing the Cartoon Network,
            Adult Swim, Boomerang and CNN websites. This contract was back in the days of
            Adobe Flash and a large part of the role was to create and animate various
            CN online gaming portals
          </article>
        </Link>
        <div className="tna" to="/aa">
          <strong>National Archives</strong>
          <article className="right">
            <h4>NATIONAL ARCHIVES</h4>
            Mike starts a contract at The National Archives to design the Archives Discovery
            Portal and more importantly the Search facility with strong focus on UXD
          </article>
        </div>
        <Link className="umg" to="/umg">
          <strong>UMG</strong>
          <article className="right">
            <h4>UNIVERSAL MUSIC GROUP</h4>
            Mike joins an exciting greenfield project at Universal Music, designing and developing
            a suite of applications to manage the production and delivery of music out to digital
            streaming services such as Apple Music, Amazon Music, Deezer, Spotify and many others
          </article>
        </Link>
        <Link className="anglo" to="/aa">
          <strong>Anglo American</strong>
          <article className="right">
            <h4>ANGLO AMERICAN</h4>
            Mike's currently working at Anglo American on a rolling contract. He's designing
            and developing data-rich trading websites for Anglo clients in Angluar and React. He's overseeing 100+
            .Net developers whilst building a fully fledged Javacript component library for Anglo American's
            huge catalogue of customer onboarding and trading applications
          </article>
        </Link>
      </section>
      {/* <img className="mike" src={Mike} alt="" /> */}
      {/* <video className='waves' autoPlay loop muted>
          <source src={Waves} type='video/mp4' />
        </video> */}
      <h1>Hi<span className="ellipsis">...</span></h1>
      <p>I’m <a href="mailto:info@pixelheads.co.uk">Mike King</a> of PixelHeads Limited<br />
        A Senior UX/UI Consultant/Designer and<br />Angular/React Front-End Developer</p>
      <p className="intro-mobile">I’m <a href="mailto:info@pixelheads.co.uk">Mike King</a> of PixelHeads Limited<br />
        A Senior UX/UI Consultant/Designer and<br />Angular/React Front-End Developer</p>
      <div className="social">
        <a href="https://www.linkedin.com/in/mike-king-0481b92/" target="blank">
          <img src={LinkedIn} alt="" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=613721312" target="blank">
          <img src={FB} alt="" />
        </a>
        <a href="https://github.com/PixelHeadsLtd" target="blank">
          <img src={GitHub} alt="" />
        </a>
      </div>
    </div>
  );
}

export default Landing;
