import PixelHeadsLogo from '../../images/pixelheads-logo.svg';

function Contact() {
  return (
    <div className="container-fluid">
      <h1>Contact</h1>
      <img src={PixelHeadsLogo} alt="" />
      <ul className="contact-panel">
        <li>07946 214544</li>
        <li>London</li>
        <li><a href="mailto:info@pixelheads.co.uk">info@pixelheads.co.uk</a></li>
      </ul>
    </div>
  );
}

export default Contact;
