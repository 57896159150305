import Research from '../../images/icons/icon-research.svg';
import Design from '../../images/icons/icon-design.svg';
import Development from '../../images/icons/icon-development.svg';

function About() {
  return (
    <div className="container-fluid">
      <h1>About</h1>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <p className="comment">Who's Mike? </p>
          <p>Mike has extensive experience in UX, Visual Design and FE Development,
            working more recently in AngularJS and ReactJS. He comes from both a creative
            and technical background and in the past has been a practitioner in Art,
            Sculpture, Music, Electrical Engineering and
            Electronics. His focus over the past 20 years has been
            Software design and development.</p>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <p className="comment">Specialities </p>
          <ul>
            <li>Visual Design – natural ability to create compelling visual designs quickly</li>
            <li>UX Design – wireframes / mock-ups / user-journey’s/ personas / user-stories</li>
            <li>Experienced typographer with a passion for colour, composition, and hierarchy</li>
            <li>High-level presentation experience to senior stake holders</li>
            <li>Design leadership experience and mentoring / managing small teams</li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <p className="comment">Clients past and present </p>
          <p>Soon after graduation Mike worked agency-side for global brands
            such as Guinness, Toshiba, Sainsburys, Barclays, Mars and Coca Cola to name a few.
            His job was to conceptualise and design brand and marketing campaigns, websites and so on.
            Following this he moved client-side, initially working for Pfizer on their global Intranet
            of over 200 websites, then later took on contracts at Discovery Channel, BBC,
            Turner Broadcasting, Universal Music and currently Anglo American building heavy data-driven
            financial trading applications for their huge client portfolio.
          </p>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <p className="comment">Why hire Mike? </p>
          <p>As mentioned, Mike has both the design experience and the front-end knowledge. This gives him
            the confidence and knowhow to produce a product from the ground up. Though some clients prefer
            to separate out roles on a project, a Designer focusing solely on UX or a Developer focusing solely
            on UI, in his experience there tends to be a disconnect between the final design and the implementation.
            Mike not only bridges this common gap, he also takes leadership in the final build.
          </p>
        </div>
      </div>
      <div className="icons">
        <div>
          <img src={Research} alt="" />
          <p className="spaced"><span>USER RESEARCH</span><br />
            PERSONAS, USER STORIES,<br />
            WIREFRAMES, TESTING</p>
        </div>
        <div>
          <img src={Design} alt="" />
          <p className="spaced"><span>VISUAL DESIGN</span><br />
            WEB & BRAND DESIGN,<br />
            TYPOGRAPHY</p>
        </div>
        <div>
          <img src={Development} alt="" />
          <p className="spaced"><span>FRONT-END DEVELOPMENT</span><br />
            MODERN FRAMEWORKS,<br />
            RESTFUL API’S</p>
        </div>
      </div>
    </div>
  );
}

export default About;
