import React from 'react';

import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';

import Header from '../header/Header';
import IntroSection from './IntroSection';
import AboutMike from './About';
import Approach from './Approach';
import MikesProjects from './Projects';
import ContactMike from './Contact';

import ActiveSection from '../../scripts/active-section';

configureAnchors({ offset: -45, scrollDuration: 500 })

const Home = () => {
  var menuRef = React.createRef();

  return (
    <section className="content">
      <ReactBody className="home-page" />
      <StickyContainer>
        <section>
          <ScrollableAnchor id={'section-home'}>
            <ActiveSection index="0" refToMenu={menuRef}>
            <Header ref={menuRef} />
            </ActiveSection>
          </ScrollableAnchor>
        </section>
        <section className="new-section home">
          <IntroSection />
        </section>
        <section className="new-section about">
          <ScrollableAnchor id={'section-about'}>
            <ActiveSection index="1" refToMenu={menuRef}>
              <AboutMike />
            </ActiveSection>
          </ScrollableAnchor>
        </section>
        <section className="new-section approach">
          <ScrollableAnchor id={'section-approach'}>
            <ActiveSection index="2" refToMenu={menuRef}>
              <Approach />
            </ActiveSection>
          </ScrollableAnchor>
        </section>
        <section className="new-section projects">
          <ScrollableAnchor id={'section-projects'}>
            <ActiveSection index="3" refToMenu={menuRef}>
              <MikesProjects />
            </ActiveSection>
          </ScrollableAnchor>
        </section>
        <section className="new-section contact">
          <ScrollableAnchor id={'section-contact'}>
            <ActiveSection index="4" refToMenu={menuRef}>
              <ContactMike />
            </ActiveSection>
          </ScrollableAnchor>
        </section>
      </StickyContainer>
    </section>
  )
}

export default Home
